// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require ckeditor/init

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")
import "bootstrap"
import "../stylesheets/application"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("@nathanvda/cocoon")

import Swiper from 'swiper';
import SwiperCore, { Mousewheel, Navigation, Pagination, Autoplay } from 'swiper';
SwiperCore.use([Mousewheel, Navigation, Pagination, Autoplay]);
import 'swiper/swiper-bundle.css';

$(document).on('turbolinks:load', function () {
  var swiper = new Swiper('.swiper-container', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    loop: true,

    autoplay: {
      delay: 2999,
      disableOnInteraction: false
    }

  });

  var swiper = new Swiper(".videoSwiper", {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 80,
    loop: true,
    navigation: {
      nextEl: ".swiper-next-button",
      prevEl: ".swiper-prev-button",
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });

  var swiper = new Swiper(".locSwiper", {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: ".swiper-next-button",
      prevEl: ".swiper-prev-button",
    },
    pagination: {
      el: ".swiper-pagination",
    },
    breakpoints: {
      550: {
        slidesPerView: 2,
      },
      930: {
        slidesPerView: 3,
        spaceBetween: 20,
      }
    }
  });

  var swiper_tours = new Swiper('.swiper-tours', {
    navigation: {
      nextEl: '.swiper-tour-next',
      prevEl: '.swiper-tour-prev',
    },

    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },

    autoplay: {
      delay: 6000,
      disableOnInteraction: false
    }

  });

  var swiper_popular_events = new Swiper('.popular-events-slider', {
    navigation: {
      nextEl: '.swiper-next-button',
      prevEl: '.swiper-prev-button',
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 30,

    autoplay: {
      delay: 6000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    breakpoints: {
      500: {
        slidesPerView: 2,
        spaceBetween: 42,
      },
      800: {
        slidesPerView: 3,
        spaceBetween: 42,
      },
      1000: {
        slidesPerView: 4,
        spaceBetween: 42,
      }
    }
  });

  var swiper_adphotos = new Swiper('.swiper-adphotos', {
    loop: true,

    autoplay: {
      // delay: 3000,
      disableOnInteraction: false
    }

  });


  //////////////////////
  $('.validate_order_artist').keyup(function () {
    styleOrderArtistSubmitbutton(isOrderArtistValid());
  });


  //////////////////////
  $('.validateContactform').keyup(function () {
    styleContactSubmitbutton(isContactValid());
  });


  ////////////////////////
  //  $('.show_more_events').on('click',function(event){
  //    event.preventDefault();

  //    let page = parseInt($('#page').val()) + 1
  //    let url = new URL(window.location.href)
  //    let location_slug = url.pathname
  //    if (location_slug == "/") {
  //      location_slug = ""
  //    }

  //    console.log('application.js')
  //    console.log(`/dashboard${location_slug}/show_more`)
  //    console.log(page)

  //    $.ajax({
  //      url: `/dashboard${location_slug}/show_more`,
  //      method: "GET",
  //      dataType: 'script',
  //      data: {page: page}
  //    })
  //  });

  ////////////////////////
  //  $('.show_more_popular_events').on('click',function(event){
  //    event.preventDefault();

  //    let popular_page = parseInt($('#popular_page').val()) + 1
  //    let url = new URL(window.location.href)
  //    let location_slug = url.pathname
  //    if (location_slug == "/") {
  //      location_slug = ""
  //    }

  //    console.log('application.js')
  //    console.log(`/dashboard${location_slug}/show_more_popular`)
  //    console.log(popular_page)

  //    $.ajax({
  //      url: `/dashboard${location_slug}/show_more_popular`,
  //      method: "GET",
  //      dataType: 'script',
  //      data: {popular_page: popular_page}
  //    })
  //  });

  //////////////////////
  $('input#searching').on('input', function () {
    let search_params = $('input#searching').val()

    $.ajax({
      url: '/main_searches/index',
      method: "GET",
      dataType: 'script',
      data: { search: search_params }
    })
  });

  //////////////////////
  $('input#searching-desktop').on('input', function () {
    let search_params = $('input#searching-desktop').val()

    $.ajax({
      url: '/main_searches/index',
      method: "GET",
      dataType: 'script',
      data: { search: search_params }
    })
  });

  //////////////////////
  $('input#admin_event_searching.form-control').on('input', function () {
    let search_params = $('input#admin_event_searching').val()

    $.ajax({
      url: '/admin/adphotos/filtered_events',
      method: "GET",
      dataType: 'script',
      data: { search: search_params }
    })
  });
});


function styleOrderArtistSubmitbutton(isValidated) {
  let submit_button = document.getElementById("btn_contact_order_artist");

  if (isValidated) {
    submit_button.disabled = false
    submit_button.style.backgroundColor = "#1350c1", submit_button.style.color = "#e9eeff";
  } else {
    submit_button.style.backgroundColor = "#dbe2fb";
    submit_button.disabled = true
  }
};


function isOrderArtistValid() {
  if (!$('#contact_name').val()) { return }
  if (!$('#contact_date').val()) { return }
  if (!$('#contact_artist').val()) { return }
  if (!$('#contact_zahid').val()) { return }
  if (!$('#contact_budget').val()) { return }
  if (!$('#contact_phone').val()) { return }

  if (($('#contact_phone').val()).length != 10) { return }

  return true;
}

$(document).on("input", "input#contact_phone.form-control.validate_order_artist.number_phone", function () {
  if (($('#contact_phone').val()).length == 10 || ($('#contact_phone').val()).length == 0) {
    document.getElementById("number_phone_warning_for_order").style.display = "none";
  } else {
    document.getElementById("number_phone_warning_for_order").style.display = "block";
  }
});


function styleContactSubmitbutton(isValidated) {
  let submit_button = document.getElementById("btn-contact");

  if (isValidated) {
    submit_button.disabled = false
    submit_button.style.backgroundColor = "#1350c1", submit_button.style.color = "#e9eeff";
  } else {
    submit_button.style.backgroundColor = "#dbe2fb";
    submit_button.disabled = true
  }
};


function isContactValid() {
  if (!$('#contact_name').val()) { return }
  if (!$('#contact_message').val()) { return }
  if (!$('#contact_phone').val()) { return }
  if (($('#contact_phone').val()).length != 10) { return }

  return true;
}

$(document).on("input", "input#contact_phone.form-control.validateContactform.number_phone", function () {
  if (($('#contact_phone').val()).length == 10 || ($('#contact_phone').val()).length == 0) {
    document.getElementById("number_phone_warning").style.display = "none";
  } else {
    document.getElementById("number_phone_warning").style.display = "block";
  }
});

let query = new URLSearchParams(new URL(window.location.href).search)
let category_ids = query.getAll('category_ids[]')

// when you first time click on location search FileSystemDirectoryReader
$(document).on("click", ".cities", function () {
  let value = $(this).val()
  let page = $('#page').val()

  $.ajax({
    url: '/dashboard/search_by_location',
    method: "GET",
    dataType: 'script',
    data: { location_value: value, delete_page: page }
  })
});

$(document).on("input", "input#location_slug.search-form__field", function () {
  let value = $(this).val()
  let page = $('#page').val()

  $.ajax({
    url: '/dashboard/search_by_location',
    method: "GET",
    dataType: 'script',
    data: { location_value: value, delete_page: page }
  })
});

$(document).on("click", "#category-all", function () {
  $("input#location_slug").val("")
  $('#realy_location_slug').val("");

  let page = $('#page').val()
  let popular_page = $('#popular_page').val()

  let url = new URL(window.location.href)
  url.searchParams.delete('category_ids[]')
  window.history.replaceState({}, 'Tickets Title', url)

  $.ajax({
    url: '/',
    method: "GET",
    dataType: 'script',
    data: { delete_page: page, delete_popular_page: popular_page }
  })
  window.location.reload();
});


$(document).on("change", ".category-checkbox", function () {
  let url = new URL(window.location.href);
  url.searchParams.delete('category_ids[]')
  window.history.replaceState({}, 'Tickets Title', url)

  let checkedValue = $(this).val()
  if (this.checked) {
    category_ids.push(checkedValue);
  } else {
    var index = category_ids.indexOf(checkedValue);
    category_ids.splice(index, 1);
  }

  category_ids.forEach((element) => {
    url.searchParams.append('category_ids[]', element)
    window.history.replaceState({}, 'Tickets Title', url)
    document.getElementById(`event_category_ids_${element}`).checked = true;
  })

  let page = $('#page').val()
  let popular_page = $('#popular_page').val()
  let locationValue = url.pathname

  $.ajax({
    url: `${locationValue}`,
    method: "GET",
    dataType: 'script',
    data: { delete_page: page, delete_popular_page: popular_page, category_ids: category_ids }
  })
});


// Search

$(document).ready(function () {
  $(".search").click(function () {
    $(".wrap, .input, .bw-search__results-wrap").toggleClass("active");
  });
});

$(document).on('turbolinks:load', function () {
  if (category_ids.length) {
    category_ids.forEach((element) => {
      $(`#event_category_ids_${element}`).checked = true;
      $(`label#category_${element}`).addClass('active');
    });
    $("#category-all").removeClass('active');
  }

  $('.category-dynamic').click(function () {
    var element = $(this).attr("id").replace("category_", "");
    $(`#category_${element}`).toggleClass('active');

    if (!($(".category-dynamic").hasClass('active'))) {
      $('#category-all').addClass('active');
    } else {
      $('#category-all').removeClass('active');
    }
  });
});

window.onload = (event) => {
  let query = new URLSearchParams(new URL(window.location.href).search)
  let category_ids = query.getAll('category_ids[]')
  let url = new URL(window.location.href);
  window.history.replaceState({}, 'Tickets Title', url)

  let showed_category_ids = document.getElementsByName("event[category_ids][]");
  let showed_category_ids_values = [];
  showed_category_ids.forEach(a => {
    if (a.value != '') {
      showed_category_ids_values.push(a.value);
    }
  });

  url.searchParams.delete('category_ids[]')
  window.history.replaceState({}, 'Tickets Title', url.href)

  if (showed_category_ids_values.length != 0) {
    category_ids.forEach(element => {
      if (!showed_category_ids_values.includes(element)) {
        var index = category_ids.indexOf(element);
        category_ids.splice(index, 1);
      }
    });


    category_ids.forEach((element) => {
      url.searchParams.append('category_ids[]', element)
      window.history.replaceState({}, 'Tickets Title', url)
      $(`#event_category_ids_${element}`).checked = true;
    });
  } else {
    category_ids = [];
    window.history.replaceState({}, 'Tickets Title', url)
    $('#category-all').addClass('active');
  }

  let page = $('#page').val()
  let popular_page = $('#popular_page').val()
  let locationValue = url.pathname

  $.ajax({
    url: `${locationValue}`,
    method: "GET",
    dataType: 'script',
    data: { delete_page: page, delete_popular_page: popular_page, category_ids: category_ids }
  })
};

$(document).on('turbolinks:load', function () {
  $('.cities').on('click', function (event) {
    event.preventDefault();
    $('.filter-cities').fadeIn();
  });
  var inputVal = $('#location_slug').val();
  $('.cities').text(inputVal || 'Усі міста');
  $('.close-popup').on('click', function (event) {
    event.preventDefault();
    $('.filter-cities').fadeOut();
  });
  $('.filter-cities').on('click', function (event) {
    if (!$(event.target).is('#search-form, #location_for_events, #location_slug')) {
      event.stopPropagation();
      $('.filter-cities').fadeOut();
    }
  });
});


